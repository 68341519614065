import React from 'react';
import line from '../../line/line.svg';
import { motion } from 'framer-motion';
import b1 from "../../line/b1.png"
// import b1 from "../../line/b2.png"
// import b1 from "../../line/b3.png"
import { Helmet } from 'react-helmet';


function Apartment() {
  return (
    <div className="relative min-h-screen pt-[180px] overflow-hidden py-16 px-4 sm:px-6 lg:px-8">

      <Helmet>
        <title>Luxury 1 BHK Apartment in Chail Himachal | Property in Hills</title>
        <meta
          name="description"
          content="Looking for luxury apartment in Chail, Himachal Pradesh? Our premiere 1 BHK apartments offer modern amenities, scenic views, and a perfect ideal property in Hills"
        />
      </Helmet>



      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="text-center max-w-4xl mx-auto flex flex-col items-center"
      >
        <h1 className="text-4xl md:text-7xl font-bold yeseva text-[#ad9b73]">
          Phase 1
        </h1>
        <img src={line} alt="line" className="w-full h-[50px] md:h-[70px] mt-[-2px] opacity-70 ml-[-5px]" />
      </motion.div>



      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="mt-8 max-w-4xl mx-auto text-justify text-gray-700 space-y-6 text-lg"
      >
        <p className='text-3xl text-[#ad9b73]/90 text-center font-semibold'>
          We are coming up with <span className='yeseva font-normal text-[#ad9b73]'>Two Apartment Blocks</span>
        </p>
        <p className='flex text-sm md:text-md items-center gap-4'>
          <img src={b1} className='h-[25px]' alt="" />
          <p>
            At Chail Heights, we bring you an exclusive residential experience through
            our thoughtfully designed Apartment Blocks. Welcome to our modern residential
            building with seven floors of comfortable living.
          </p>
        </p>

        <p className='flex items-center text-sm md:text-md gap-4'>
          <img src={b1} className='h-[25px]' alt="" />
          <p>
            Each floor features four spacious 1 BHK apartments. One entire floor is designated for
            resident parking, with accommodation for up to 25 car spaces.
          </p>
        </p>

        <p className='flex items-center text-sm md:text-md gap-4'>
          <img src={b1} className='h-[25px]' alt="" />
          <p>
            Located in the serene beauty of Chail, these homes offer a harmonious blend of comfort,
            luxury, and modern living.
          </p>
        </p>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mt-8"
        >
          <h2 className="text-4xl text-center font-bold text-[#ad9b73] yeseva mt-12">Buildings Layout</h2>
          <img src={line} alt="line" className="w-full h-[50px] opacity-70 mb-8 ml-[-5px]" />


          <div className="flex flex-col md:flex-row gap-5">
            <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/apartmentview.jpg"} alt="Building Layout" className="w-full md:w-1/2 h-auto rounded-lg shadow-lg" />
            <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/apartmentview2.jpg"} alt="Building Layout" className="w-full md:w-1/2 h-auto rounded-lg shadow-lg" />
          </div>
          <ul className="mt-8 md:text-2xl text-md list-inside space-y-2 text-left">
            <p className='flex items-center gap-4'>
              <img src={b1} className='h-[25px]' alt="" />
              <li>7 Floors (in all)</li>
            </p>
            <p className='flex items-center gap-4'>
              <img src={b1} className='h-[25px]' alt="" />
              <li>4 Apartments on each floor</li>
            </p>
            <p className='flex items-center gap-4'>
              <img src={b1} className='h-[25px]' alt="" />
              <li>1 Floor reserved for Designated Car Parking Space</li>
            </p>
          </ul>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mt-8"
        >
          <h2 className="text-4xl font-bold text-[#ad9b73] text-center yeseva mt-16">Floor Layout</h2>
          <img src={line} alt="line" className="w-full h-[50px] opacity-70 ml-[-5px]" />


          <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/apartmentplan.jpg"} alt="Building Layout" className="w-full h-auto mt-12 rounded-lg shadow-lg" />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="mt-8"
        >
          <h2 className="text-4xl font-bold text-[#ad9b73] yeseva mt-16 text-center">Apartment Sizes</h2>
          <img src={line} alt="line" className="w-full h-[50px] opacity-70 mb-4 ml-[-5px]" />
          <ul className="flex flex-col md:flex-row gap-8">
          <p className="w-full md:w-1/2">
              <li className="mb-3 text-center text-[#ad9b73] "><span className='text-xl yeseva text-gray-700 font-bold'>855 sq.ft.</span><br />For a more compact yet cozy layout.</li>
              <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/floorplan2.jpg"} alt="Apartment Size Options" className="w-full h-auto object-contain" />
            </p>
            <p className="w-full md:w-1/2">
              <li className="mb-3 text-center text-[#ad9b73] "><span className='text-xl yeseva text-gray-700 font-bold'>977 sq.ft.</span><br />For those seeking expansive living spaces.</li>
              <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/floorplan.jpg"} alt="Apartment Size Options" className="w-full h-auto object-contain" />
            </p>
           

          </ul>
        </motion.div>

        <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="rounded-lg p-6 mt-5 text-center space-y-4"
          >
            <a
            href='https://d2r3u9c7j9d9om.cloudfront.net/pdf/Chail.pdf'
            rel="noopener noreferrer"
            target="_blank"
            >
              <button

              className="bg-gradient-to-r from-[#ad9b73] to-[#80702F] text-white px-4 py-2 rounded-lg"
            >
              Download PDF
            </button>
            </a>
          </motion.div>

      </motion.div>
    </div>
  );
}

export default Apartment;
